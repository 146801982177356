import './App.css';

function App() {
  return (
    <div>
      <header className="header">
        <img src="https://selkot.info:8080/resources/frodi/photos/misc/logo.png" className="logo" alt="logo" />
        <p>
          Fáðu þér straum!
        </p>
      </header>
      <div className="body">
        <div>
          <h2>Streymisveitan felur í sér eftirfarandi atriði:</h2>
          <ul>
              <li>Innskráningu fyrir notendur.</li>
              <li>Vandaða og vel úthugsaða vefþjónustu.</li>
              <li>Structured query language (i.e., SQL).</li>
              <li>Gagnagrunn sem er afritaður daglega.</li>
              <li>Upplýsingakerfi hýst í skýinu.</li>
              <li>Sérstakan biðlara fyrir minni skrár.</li>
              <li>Yfir 1000 afurðir.</li>
              <li>Vefgátt svo hægt sé að senda okkur nýjar afurðir (<a className="app-link" href="https://uploads.frodi.info" target="_blank" rel="noopener noreferrer">https://uploads.frodi.info/</a>).</li>
              <li>Margt fleira.</li>
          </ul>
        </div>
      </div>
    </div>
  );
}

export default App;
